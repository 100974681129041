<template>
  <div class="letter-item">
    <div class="md-content letter-content">
      <vue3-markdown-it :source="letterBody" />
    </div>
    <div v-if="contributor" class="letter-credits">
      <div class="courtesy">Courtesy of</div>
      <div class="contributor">{{ contributor }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LetterItem",
  props: {
    id: Number,
    letterBody: String,
    contributor: String,
  },
  computed: {
    isOnSecond() {
      return this.id % 2 === 0;
    },
    isOnThird() {
      return this.id % 3 === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style-vars.scss";
.letter-item {
  max-width: 760px;
  background-color: white;
  color: black;
  background-color: rgb(247, 247, 239);
  // background-color: #fff;
  background-image: linear-gradient(
      90deg,
      transparent 29px,
      #abced4 29px,
      #abced4 31px,
      transparent 31px
    ),
    linear-gradient(#eee 0.1em, transparent 0.1em);
  background-size: 100% 1.2em;
  border: 0.5px solid $light-gray;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  margin: 4rem auto 10rem auto;
  padding: 8rem 1rem 10rem 2rem;
  .letter-content {
    font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter,
      monospace;
    text-align: left;
  }
  hr {
    margin: 4rem 0 2rem 0;
  }
  .letter-credits {
    margin: 6rem 2rem 0 2rem;
    border: 1px solid #abced4;
    background-color: rgba(255, 255, 255, 0.25);
    padding: 4rem;
    .courtesy {
      font-size: 1.2rem;
      text-transform: uppercase;
    }
    .contributor {
      font-weight: 600;
    }
  }
}
/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  .letter-item {
    background-image: linear-gradient(
        90deg,
        transparent 79px,
        #abced4 79px,
        #abced4 81px,
        transparent 81px
      ),
      linear-gradient(#eee 0.1em, transparent 0.1em);
    padding: 8rem 4rem 10rem 8rem;
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}
</style>
