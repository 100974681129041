import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/index.html",
    redirect: "/",
  },
  {
    path: "/index",
    redirect: "/",
  },
  {
    path: "/posters",
    name: "Posters",
    component: () => import("../views/Posters.vue"),
  },
  {
    path: "/meditations",
    name: "Meditations",
    component: () => import("../views/Meditations.vue"),
  },
  {
    path: "/installations",
    name: "Installations",
    component: () => import("../views/Installations.vue"),
  },
  {
    path: "/installations/:slug",
    name: "Installation",
    component: () => import("../views/Installation.vue"),
  },
  {
    path: "/letters",
    name: "Letters",
    component: () => import("../views/Letters.vue"),
  },
  {
    path: "/words-and-actions",
    name: "Words-Actions",
    component: () => import("../views/Words-Actions.vue"),
  },
  {
    path: "/publications-and-interviews",
    name: "Publications-Interviews",
    component: () => import("../views/Publications-Interviews.vue"),
  },
  {
    path: "/shows-and-events",
    name: "Shows-Events",
    component: () => import("../views/Shows-Events.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/press",
    name: "Press",
    component: () => import("../views/Press.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../components/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: "http://dearclimate.net/",
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
