<template>
  <div class="meditation-player">
    <div
      id="hero"
      class="hero-image"
      :style="'background-image : url(' + heroImgURL + ')'"
    ></div>
    <audio
      class="audio-file"
      preload="metadata"
      :id="slug"
      @playing="updateTimecode()"
      @ended="resetAudio()"
      @loadedmetadata="displayDuration()"
    >
      <p>Your browser does not support the audio element</p>
      <source :src="audioLoc" type="audio/mpeg" />
    </audio>
    <div class="audio-controls">
      <div
        class="play-pause-btn"
        :class="isPlaying ? 'pause' : ''"
        @click="togglePlayPause()"
      ></div>
      <div class="audio-displays">
        <h1 class="title">{{ title }}</h1>
        <div class="audio-timeline">
          <div class="timeline">
            <input
              type="range"
              class="seek-slider"
              max="100"
              value="0"
              :id="slug + '-seekBar'"
              @change="seek()"
            />
          </div>
          <div :id="slug + '-timecode'" class="timecode">00:00</div>
          <div class="timecode duration">{{ audioDur }}</div>
        </div>
      </div>
    </div>
    <div class="meta">
      <div class="description">{{ description }}</div>
      <div class="credits">
        <p v-if="readBy">Read by {{ readBy }}</p>
        <p>Sound design by Pejk Malinovski</p>
      </div>
    </div>
  </div>
</template>

<script>
var intervalTimecode = null;
export default {
  name: "MeditationItem",
  props: {
    title: String,
    slug: String,
    description: String,
    readBy: String,
    soundDesignBy: String,
    heroImgURL: String,
    audioLoc: String,
  },
  data: function () {
    return {
      isPlaying: false,
      audioDur: "00:00",
    };
  },
  methods: {
    seek() {
      //Calcuate corresponding time of audio
      const resultTime =
        (document.getElementById(this.slug + "-seekBar").value / 100) *
        this.getAudioDuration();
      //Set the audio to the time seeked
      const audioFile = document.getElementById(this.slug);
      audioFile.currentTime = resultTime;
      //Get timecode and display
      const resultTimecode = this.toTimecode(resultTime);
      document.getElementById(this.slug + "-timecode").innerHTML =
        resultTimecode;
      // console.log(`Seeking to ${resultTimecode}`);
    },
    togglePlayPause() {
      if (this.isPlaying) {
        this.pauseAudio();
        this.stopTimecode();
        this.isPlaying = !this.isPlaying;
      } else {
        this.playAudio();
        this.updateTimecode;
        this.isPlaying = !this.isPlaying;
      }
    },
    playAudio() {
      const audioFile = document.getElementById(this.slug);
      audioFile.play();
    },
    pauseAudio() {
      const audioFile = document.getElementById(this.slug);
      audioFile.pause();
    },
    resetAudio() {
      this.stopTimecode();
      const audioFile = document.getElementById(this.slug);
      audioFile.currentTime = 0;
      const seekBar = document.getElementById(this.slug + "-seekBar");
      seekBar.value = 0;
    },
    updateTimecode() {
      if (!intervalTimecode) {
        // console.log("Playing audio.");
        intervalTimecode = setInterval(this.getCurrentTimecode, 1000);
      }
    },
    stopTimecode() {
      clearInterval(intervalTimecode);
      intervalTimecode = null;
    },
    getCurrentTimecode() {
      //Set timecode
      const audioFile = document.getElementById(this.slug);
      const timecode = this.toTimecode(audioFile.currentTime);
      const timecodeEle = document.getElementById(this.slug + "-timecode");
      timecodeEle.innerHTML = timecode;
      //Set seek bar position
      this.setSeekBarThumb(audioFile.currentTime, this.getAudioDuration());
      // console.log(timecode);
    },
    getAudioDuration() {
      const audioFile = document.getElementById(this.slug);
      // console.log(`Audio duration: ${audioFile.duration}`);
      return audioFile.duration;
    },
    displayDuration() {
      const duration = this.toTimecode(this.getAudioDuration());
      this.audioDur = duration;
    },
    toTimecode(duration) {
      const minutes = Math.floor(duration / 60).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      const seconds = Math.floor(duration % 60).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      const timecode = minutes + ":" + seconds;
      return timecode;
    },
    toSeekBarPos(currentTime, duration) {
      return ((currentTime / duration) * 100).toFixed(2);
    },
    setSeekBarThumb(currentTime, duration) {
      const seekBar = document.getElementById(this.slug + "-seekBar");
      // console.log("VAL: ", this.toSeekBarPos(currentTime, duration));
      seekBar.value = this.toSeekBarPos(currentTime, duration);
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../assets/style-vars.scss";

//BEGIN SLIDER STYLES FOR PLAYER
//------------

// Resets
input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

// Thumb styles
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0;
  height: 10px;
  width: 10px;
  border-radius: 0;
  background: #333;
  cursor: pointer;
  margin-top: 0; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 0; /* Add cool effects to your sliders! */
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0;
  background: $light-gray;
  border-radius: 0;
  border: 0;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: $light-gray;
}

// END PLAYER STYLES
//-------------------------
.meditation-player {
  text-align: left;
  background-color: black;
  color: white;
  margin-bottom: 6rem;
  .hero-image {
    min-height: 18rem;
    background-color: $light-gray;
    background-image: url("../assets/audio-player/meditation-hero.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .audio-controls {
    display: flex;
    align-items: center;
    padding: 2rem 2rem 0.5rem 2rem;
    .play-pause-btn {
      cursor: pointer;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      background-color: $light-gray;
      flex-grow: 0;
      flex-shrink: 0;
      background-image: url("../assets/audio-player/play-ico.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-size: 39%;
      background-position-y: center;
      background-position-x: 59%;
      &.pause {
        background-image: url("../assets/audio-player/pause-ico.png");
        background-position-x: center;
      }
    }
    .audio-displays {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 2rem;
      .title {
        font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica,
          Arial, sans-serif;
        font-size: 3rem;
        font-weight: 500;
        margin: 0.5rem 0;
        color: white;
        text-transform: uppercase;
      }
      .audio-timeline {
        display: flex;
        align-items: center;
        .timeline {
          width: 100%;
          .seek-slider {
            width: 100%;
            margin: 0;
            padding: 0;
          }
        }
        .timecode {
          padding-left: 1rem;
          font-size: 1.2rem;
          color: rgb(135, 135, 135);
          &.duration {
            border-left: 1px solid rgb(96, 96, 96);
            padding-left: 0.5rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
  .meta {
    padding: 2rem;
    .description {
      margin: 0 0 0.5rem 0;
      font-size: 1.8rem;
    }
    .credits {
      p {
        margin: 0;
        color: $light-gray;
      }
    }
  }
}
</style>
