<template>
  <div id="footer">
    <div class="footer-sitemap">
      <h1>Site Map</h1>
      <div class="links">
        <div class="about-project-links">
          <ul>
            <li><router-link to="/about">About Dear Climate</router-link></li>
            <li><router-link to="/press">Press Coverage</router-link></li>
            <li><router-link to="/contact">Contact Us</router-link></li>
          </ul>
        </div>
        <div class="documentation-links">
          <ul>
            <li><router-link to="/posters">Posters</router-link></li>
            <li><router-link to="/meditations">Meditations</router-link></li>
            <li>
              <router-link to="/installations">Installations</router-link>
            </li>
            <li><router-link to="/letters">Letters</router-link></li>
            <li>
              <router-link to="/words-and-actions"
                >Words &amp; Actions</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <p>
        Dear Climate © 2012 – {{ new Date().getFullYear() }},
        <a
          href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
          target="_blank"
        >
          CC BY-NC-ND 4.0</a
        >
      </p>
      <p>
        We use the Creative Commons license
        <a
          href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
          target="_blank"
          >'attribution/noncommercial/no derivatives.'</a
        >
      </p>
      <p>
        This means you can download anything but you cannot change it or use it
        commercially. You also need to credit us.
      </p>
      <p class="web-dev">
        Web design and development by
        <a href="https://patshiu.com/" target="_blank">Pat Shiu</a>.
      </p>
    </div>
  </div>
</template>
<style scoped lang="scss">
#footer {
  display: flex;
  flex-direction: column;
  padding: 0;
  p,
  a,
  a:active,
  a:focus,
  a:visited {
    color: white;
    text-decoration: none;
    border-bottom: 2px solid transparent;
  }
  a:hover {
    border-bottom: 2px solid white;
  }
  .footer-sitemap {
    background-color: #262626;
    color: white;
    padding: 4rem 1.5rem 6rem 1.5rem;
    h1 {
      display: none;
    }

    .links {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      ul {
        list-style: none;
        text-align: left;
        padding: 0;
        margin: 0 2rem 0 2rem;
      }
      ul,
      li {
        color: white;
      }
    }
  }
  .footer-copyright {
    background-color: #111;
    text-align: left;
    padding: 4rem 4rem 8rem 4rem;
    p {
      font-size: 1.2rem;
      margin-bottom: 0.8rem;
    }
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  #footer {
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    .footer-sitemap {
      flex-grow: 2;
      .links {
        justify-content: flex-end;
      }
    }
    .footer-copyright {
      flex-grow: 1;
    }
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}
</style>
