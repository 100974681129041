<template>
  <div class="installation-feature-item">
    <div class="left-col">
      <h1>{{ title }}</h1>
      <div>
        <vue3-markdown-it v-if="location" :source="location" class="location" />
      </div>
      <div class="dates" v-if="dates">{{ dates }}</div>
      <div class="blurb">
        <vue3-markdown-it :source="blurb" />
      </div>
      <div class="button-container">
        <a class="button sml cta-black" :href="'/installations/' + slug"
          >View Installation</a
        >
      </div>
    </div>
    <div class="right-col">
      <div class="hero-image">
        <Carousel :wrap-around="true">
          <Slide v-for="image in heroImgs" :key="image.url">
            <div class="carousel__item">
              <!-- {{ image.url }} -->
              <div
                class="image-container"
                role="image"
                alt="test"
                :style="{ backgroundImage: 'url(' + image.url + ')' }"
              ></div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
export default {
  name: "InstallationFeatureItem",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    title: String,
    location: String,
    dates: String,
    credits: String,
    blurb: String,
    slug: String,
    heroImgs: Array,
  },
  data() {
    return {
      slides: [
        {
          id: 1,
          alt: "Photo of Whale Fall Feast installation",
          url: "/installations-imgs/whale_fall_feast/WF_01-1whalewhole-IMG_0437.jpg",
        },
        {
          id: 2,
          alt: "Photo of Whale Fall Feast installation",
          url: "/installations-imgs/whale_fall_feast/WF_07-5firstCourse-2021-06-25 17.14.16.jpg",
        },
        {
          id: 3,
          alt: "Photo of Whale Fall Feast installation",
          url: "/installations-imgs/whale_fall_feast/WF_08-5firstCourse-IMG_0481.jpg",
        },
      ],
    };
  },
  computed: {
    bgImgStyle(imgUrl) {
      console.log(imgUrl);
      return `background-image: url(hi);`;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style-vars.scss";

// CAROUSEL STYLES
.carousel__item {
  min-height: 300px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  .image-container {
    width: 100%;
    height: 480px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../public/installations-imgs/whale_fall_feast/WF_01-1whalewhole-IMG_0437.jpg");
  }
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
.carousel__pagination {
  margin-bottom: 0;
}

@import "@/assets/style-vars.scss";

.installation-feature-item {
  display: flex;
  flex-direction: column-reverse;
  padding: 0;
  margin-bottom: 6rem;
  border-bottom: 1px solid $light-gray;
  background-color: rgba(255, 255, 255, 0.6);
  .left-col {
    flex: 1;
    text-align: left;
    padding: 2rem 2rem 6rem 2rem;
    h1 {
      font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 1.2rem;
      margin-top: 4rem;
    }
    .location {
      font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
      * > a {
        color: black;
      }
    }

    .dates {
      font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
      padding-bottom: 1.2rem;
    }
    .blurb {
      font-family: monospace;
      padding: 0;
      * > a {
        color: black;
      }
    }
    .button-container {
      text-align: center;
    }
  }
  .right-col {
    flex: 1;
    .hero-image {
      min-height: 300px;
      border: 10px solid #111;
      margin-bottom: 4rem;
    }
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  .installation-feature-item {
    padding: 4rem;
    .left-col {
      .blurb {
        padding: 0 4rem 4rem 0;
      }
      .button-container {
        text-align: left;
      }
    }
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}

// For dark color theme
.installation-feature-item {
  &.dark {
    background-color: #111;
    .left-col {
      color: white;
      h1 {
        color: white;
      }
      .location {
        color: white;
        * > a,
        * > a:visited {
          color: white;
        }
      }
      .dates {
        color: white;
      }
      .blurb {
        color: white;
        * > a,
        * > a:visited {
          color: white;
        }
      }
    }
    .right-col {
      .hero-image {
        border-color: white;
      }
    }
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  .installation-feature-item {
    flex-direction: row;
  }
}
/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}
</style>
