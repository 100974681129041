<template>
  <head>
    <title>Test Title</title>
  </head>
  <div class="app-container">
    <Nav />
    <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import "./assets/normalize.css";
import "./assets/skeleton.css";
import "./assets/global.scss";

export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
};
</script>
<style lang="scss">
.app-container {
  display: flex;
  min-height: calc(100vh - 85px); //to compensate for height of navbar
  flex-direction: column;
  justify-content: space-between;
}
</style>
