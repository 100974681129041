<template>
  <div class="container page home">
    <!-- Landing Header -->
    <div class="home-header">
      <div class="home-header-text">
        <h5 class="mini-subhead">About</h5>
        <div>
          <h1 class="rot-2">DEAR CLIMATE</h1>
          <p class="p-large">
            is an ongoing creative-research project that hacks the aesthetics of
            instructional signage and the techniques of meditation to lead
            viewers and listeners towards a better informed, more realistic, and
            more affectionate relationship to the more-than-human world,
            including geo-physical forces, and other species.
          </p>
          <router-link to="/about" class="button cta-black"
            >Read More</router-link
          >
        </div>
      </div>
      <div class="hero-carousel">
        <!-- TODO: HERO IMAGES CAROUSEL GOES HERE -->
      </div>
    </div>
    <!-- Landing Press -->
    <div class="row">
      <div class="home-press">
        <h5 class="brush white">Press Coverage</h5>
        <div class="press-logos">
          <div class="logo smithsonian"></div>
          <div class="logo nyt"></div>
          <div class="logo hyperallergic"></div>
          <div class="logo guardian"></div>
          <div class="logo hustonchronicle"></div>
        </div>
        <router-link to="/about" class="white uppercase-bold"
          >View full press list</router-link
        >
      </div>
    </div>
    <!-- Disruption Banner -->
    <div class="disruption-banner">
      <div class="graffiti">
        <img src="@/assets/banner-climate-love.jpg" />
      </div>
      <div class="disruption-banner-image fire">
        <img src="@/assets/banner-forest-fire.png" />
      </div>
    </div>
    <!-- Feature: Posters -->
    <div class="feature-section dark posters">
      <div class="posters-bg"></div>
      <h1 class="rot2">Posters</h1>
      <h2>
        Duplicate. Disseminate. Agitate.<br />
        Duplicate. Disseminate. Agitate.<br />
        Duplicate. Disseminate. Agitate.
      </h2>
      <div class="feature-content posters">
        <PosterFeatureItem
          v-for="poster in posters"
          :key="poster.id"
          :id="poster.id"
          :pathLong="poster.pathLong"
          :pathShort="posters.pathShort"
        />
      </div>
      <div class="feature-blurb">
        These posters can be printed from your computer, hung up in your
        workplace cafeteria or school lunchroom, sheet-mulched into your
        neighborhood carbon capturing food forest or slipped into the magazine
        rack at a freeway filling station.
      </div>
      <router-link to="/posters" class="button cta-white"
        >Browse All</router-link
      >
    </div>
    <!-- Disruption Banner -->
    <div class="disruption-banner">
      <div class="graffiti">
        <img src="@/assets/banner-question-authority.png" />
      </div>
      <div class="disruption-banner-image lifeboat">
        <img src="@/assets/banner-lifeboat.png" />
      </div>
    </div>
    <!-- Feature: Meditations -->
    <div class="feature-section dark meditations">
      <h1 class="rot-2">Meditations</h1>
      <h2>
        Breathe in. Watch out.<br />
        Breathe in. Watch out.<br />
        Breathe in. Watch out.
      </h2>
      <div class="feature-content meditation">
        <MeditationItem
          title="Laguardia"
          slug="laguardia"
          description="Detailed Blessings for Times of Transit"
          readBy="Seth Kanor"
          heroImgURL="/meditations-audio/meditation_01-laguardia.jpg"
          audioLoc="/audio/laguardia.wav"
        />
        <MeditationItem
          title="Hello Virus"
          slug="hello-virus"
          description="Imagine World Peace"
          readBy="Jessica Almasy"
          heroImgURL="/meditations-audio/meditation_02-dear_virus.jpg"
          audioLoc="/audio/hello_virus.mp3"
        />
      </div>
      <div class="feature-blurb">
        These meditations could be used at weddings, picnics, concerts, sports
        events, raves and retreats, or you might enjoy them while lying in your
        bathtub (empty or full).
      </div>
      <router-link to="/meditations" class="button cta-white"
        >Browse All</router-link
      >
    </div>
    <!-- Disruption Banner -->
    <div class="disruption-banner">
      <div class="graffiti">
        <!-- No Content -->
      </div>
      <div class="disruption-banner-image inequality">
        <img src="@/assets/banner-inequality.png" />
      </div>
    </div>
    <!-- Feature: Installations -->
    <div class="feature-section installations">
      <h5 class="brush">Featured</h5>
      <h1>Installations</h1>
      <div class="feature-content">
        <div class="installations-content">
          <InstallationFeatureItem
            v-for="item in installations"
            :key="item.id"
            :title="item.title"
            :location="item.location"
            :dates="item.dates"
            :credits="item.credits"
            :blurb="item.blurb"
            :slug="item.slug"
            :heroImgs="item.heroImgs"
          />
        </div>
      </div>
      <router-link to="/installations" class="button cta-black"
        >Browse All</router-link
      >
    </div>
    <!-- Disruption Banner -->
    <div class="disruption-banner">
      <div class="graffiti">
        <!-- No Content -->
      </div>
      <div class="disruption-banner-image traffic">
        <img src="@/assets/banner-traffic.png" />
      </div>
    </div>
    <!-- Feature: Letters -->
    <div class="feature-section dark letters">
      <h1>Letters</h1>
      <h2>
        What would other earthlings say?<br />
        What would other earthlings say?<br />
        What would other earthlings say?
      </h2>
      <div class="feature-content">
        <LetterItem
          :id="letters[0].id"
          :letterBody="letters[0].letterBody"
          :contributor="letters[0].contributor"
        />
      </div>
      <div class="feature-blurb">
        Letters welcomed from all entities, species, objects, substances, forces
        . . . Speak UP and Speak FOR for the planet Send your messages to
        <a href="mailto:dearclimate@gmail.com">dearclimate@gmail.com</a>. We’ll
        forward them to the climate.
      </div>
      <router-link to="/letters" class="button cta-white"
        >Browse All</router-link
      >
    </div>
    <!-- Disruption Banner -->
    <div class="disruption-banner">
      <div class="disruption-banner-image ewaste">
        <img src="@/assets/banner-e-waste.png" />
      </div>
      <div class="graffiti">
        <img src="@/assets/banner-cultivate.png" />
      </div>
    </div>
    <!-- Link Prompter -->
    <LinkPrompter :links="promptLinks" />
    <!-- Contact Us -->
    <div class="contact-section">
      <hr />
      <h1>Contact Us</h1>
      <p>
        If you would like to find out more, or discuss future installations and
        interventions, please contact us at
        <a href="mailto:dearclimate@gmail.com">dearclimate@gmail.com</a>
      </p>
      <hr />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PosterFeatureItem from "@/components/PosterFeatureItem.vue";
import MeditationItem from "@/components/MeditationItem.vue";
import InstallationFeatureItem from "@/components/InstallationFeatureItem.vue";
import LetterItem from "@/components/LetterItem.vue";
import LinkPrompter from "@/components/LinkPrompter.vue";

import installationsJSON from "@/page-contents/installations.json";

import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    const siteData = reactive({
      title: `DEAR CLIMATE`,
      description: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:description`,
          content: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
        },
        {
          property: `og:site_name`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `http://www.dearclimate.net`,
        },
        {
          property: `og:image`,
          content: `http://www.dearclimate.net/dear-climate-og-img.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: `http://www.dearclimate.net/`,
        },
        {
          name: `twitter:title`,
          content: computed(() => siteData.title),
        },
        {
          name: `twitter:description`,
          content: computed(() => siteData.description),
        },
        {
          name: `twitter:image`,
          content: `http://www.dearclimate.net/dear-climate-og-twitter-img.jpg`,
        },
      ],
    });
  },
  name: "Home",
  components: {
    PosterFeatureItem,
    MeditationItem,
    InstallationFeatureItem,
    LetterItem,
    LinkPrompter,
  },
  data: function () {
    return {
      posters: [],
      meditations: [],
      letters: [
        {
          id: 1,
          letterBody:
            "TO: The Climate\nDATE:   21 December 2015\nSUBJECT:    Corrective Action\n\n---\n\nThis memo constitutes a formal corrective action and this document will be placed in your permanent personnel file and could be used to make decisions that effect your employment.  This document identifies areas where your performance is inconsistent with what is expected.\n\n**Issues**\n1.  Lack of adherence to quarterly deadlines\n2.  Lack of teamwork to the point of alienating coworkers\n3.  Poor work ethic\n4.  Reckless endangerment of marginal populations\n5.  Inconsistent performance\n6.  Excessive retention of carbon dioxide\n7.  Volatile and stormy outbursts\n8.  Ocean Acidification\n\n**Expectations**\n1.  Adherence to terms of our Original Contract\n2.  Willingness to take responsibility for your actions\n3.  Willingness to take orders from superiors\n\nThe performance issues identified above are not acceptable in this work place. These behaviors have a negative impact on your co-workers and ultimately our community.  As a member of our team you must improve your performance and behavior to bring it into compliance with expectations.  I want to be sure that you understand the importance of this issue.  \n\nFailure to achieve immediate and sustained improvement in the expectation(s) outlined above may result in further corrective action, up to and including termination.  As always, I will be available to assist you in answering any questions or concerns you may have. \n  \nHUMAN RESOURCES DEPARTMENT  \n  ",
          contributor: "Peggy Estela",
        },
      ],
      installations: [],
      promptLinks: [
        {
          title: "Publications & Interviews",
          description: "Writings and interviews by DEAR CLIMATE.",
          target: "/publications-and-interviews",
        },
        {
          title: "Shows & Events",
          description: "Lectures, panels, and appearances in group shows.",
          target: "/shows-and-events",
        },
        {
          title: "Press",
          description: "Media coverage on DEAR CLIMATE.",
          target: "/press",
        },
      ],
    };
  },
  methods: {
    fetchPosters(r) {
      //console.log(`fetchPosters() called with:`);
      // console.log(r);
      let id = 1;
      r.keys().forEach((key) => {
        // console.log(key);
        this.posters.push({
          id: id,
          pathLong: r(key),
          pathShort: key,
        });
        id++;
      });
      // console.log("Getting data on featured posters.");
    },
    processInstallationsData(input) {
      const fetchedData = input.data;
      //TODO: Check for whether article is featured
      for (let i = 0; i < 3; i++) {
        let thisEntry = {};
        thisEntry.id = fetchedData[i].id;
        thisEntry.title = fetchedData[i].attributes.title;
        thisEntry.dates = fetchedData[i].attributes.dates;
        thisEntry.location = fetchedData[i].attributes.location;
        thisEntry.slug = fetchedData[i].attributes.slug;
        thisEntry.credits = fetchedData[i].attributes.credits;
        thisEntry.heroImgs = [];
        if (fetchedData[i].attributes.hero1) {
          // console.log("FETCHED HERO1", fetchedData[i].attributes.hero1);
          let heroImg = {};
          heroImg.url = fetchedData[i].attributes.hero1;
          heroImg.alt = "Alt text will go here.";
          thisEntry.heroImgs.push(heroImg);
        }
        if (fetchedData[i].attributes.hero2) {
          // console.log("FETCHED HERO2", fetchedData[i].attributes.hero2);
          let heroImg = {};
          heroImg.url = fetchedData[i].attributes.hero2;
          heroImg.alt = "Alt text will go here.";
          thisEntry.heroImgs.push(heroImg);
        }
        if (fetchedData[i].attributes.hero3) {
          // console.log("FETCHED HERO3", fetchedData[i].attributes.hero3);
          let heroImg = {};
          heroImg.url = fetchedData[i].attributes.hero3;
          heroImg.alt = "Alt text will go here.";
          thisEntry.heroImgs.push(heroImg);
        }

        //FOR REWRITING PATHS TO IMGS UPLOADED TO STRAPI
        // const relPathWriteup = fetchedData[i].attributes.writeup;
        // const absPathWriteup = relPathWriteup.replaceAll(
        // "/uploads/",
        // "https://dear-climate-cms.herokuapp.com/uploads/"
        // );
        // thisEntry.writeup = absPathWriteup;

        // thisEntry.writeup = fetchedData[i].attributes.writeup;
        thisEntry.blurb = fetchedData[i].attributes.blurb;

        // console.log("This entry: ", thisEntry);
        this.installations.push(thisEntry);
      }
      // console.log(`Data loaded: ${input.data}`);
    },
  },
  mounted() {
    this.fetchPosters(
      require.context("../../public/posters-featured/", true, /\.jpg$/)
    );
    this.processInstallationsData(installationsJSON);
  },
};
</script>

<style lang="scss" scoped>
.home-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .home-header-text {
    padding: 4rem;
    flex: 1;
    flex-grow: 1;
  }
  .hero-carousel {
    min-height: 300px;
    flex: 1;
    flex-grow: 1;
    background-image: url("../assets/Landing-Dear Climate-MU-Hanneke_Wetzer.jpg");
    background-size: cover;
    background-position: center;
  }
}
/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .home-header {
    flex-direction: row;
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}

.home-press {
  background: black;
  color: white;
  padding: 4rem 2rem 6rem 2rem;
  .press-logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 4rem 0;
    .logo {
      height: 42px;
      width: 200px;
      margin: 2rem;
      background-image: url("../assets/logos/Logo-Guardian.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      &.smithsonian {
        background-image: url("../assets/logos/Logo-SmithsonianMag.png");
      }
      &.nyt {
        background-image: url("../assets/logos/Logo-NYT.png");
      }
      &.hyperallergic {
        background-image: url("../assets/logos/Logo-Hyperallergic.png");
      }
      &.theguardian {
        background-image: url("../assets/logos/Logo-Guardian.png");
      }
      &.hustonchronicle {
        background-image: url("../assets/logos/Logo-Houston-Chronicle.png");
      }
    }
  }
}
/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  .home-press {
    padding: 2rem 2rem 4rem 2rem;
    .press-logos {
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem 6rem 4rem 6rem;
    }
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}

.disruption-banner {
  padding: 0;
  margin: 0;
  .graffiti {
    img {
      width: 100%;
      height: auto;
    }
  }
  .disruption-banner-image {
    img {
      width: 100%;
      height: auto;
      margin-top: -7rem;
      margin-bottom: -7rem;
    }
  }
  /* Larger than mobile */
  @media (min-width: 400px) {
  }

  /* Larger than phablet (also point when grid becomes active) */
  @media (min-width: 550px) {
  }

  /* Larger than tablet */
  @media (min-width: 750px) {
    .disruption-banner-image {
      img {
        margin-top: -8rem;
        margin-bottom: -7rem;
      }
      &.fire,
      &.lifeboat,
      &.traffic {
        img {
          margin-top: -8rem;
          margin-bottom: -7rem;
        }
      }
      &.inequality {
        img {
          margin-top: -9rem;
          margin-bottom: -10rem;
        }
      }
    }
  }

  /* Larger than desktop */
  @media (min-width: 1000px) {
    .disruption-banner-image {
      img {
        margin-top: -13rem;
        margin-bottom: -10rem;
      }
      &.fire,
      &.lifeboat,
      &.ewaste {
        img {
          margin-top: -13rem;
          margin-bottom: -10rem;
        }
      }
      &.traffic {
        img {
          margin-top: -13rem;
          margin-bottom: -14rem;
        }
      }
      &.inequality {
        img {
          margin-top: -14rem;
          margin-bottom: -19rem;
        }
      }
    }
  }

  /* Larger than Desktop HD */
  @media (min-width: 1200px) {
  }
}

.feature-section {
  padding: 10rem 2rem 20rem 2rem;
  h1 {
    font-size: 4rem;
    margin-bottom: -1rem;
  }
  h2 {
    font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-size: 1.8rem;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
    line-height: 0.8;
    text-shadow: 1px 1px 0 black;
  }

  &.dark {
    background-color: black;
    color: white;
    h1,
    h2 {
      color: white;
    }
  }

  &.posters {
    background-image: url(../assets/backgrounds/DC_rain-on-black@2x.png);
    background-size: 50px;
    padding-bottom: 8rem;
    h1 {
      font-size: 5.5rem;
      margin-bottom: -1rem;
    }
  }

  &.meditations {
    padding-bottom: 10rem;
    background-image: url(../assets/backgrounds/DC_flood-on-black@2x.png);
    background-size: 150px;
  }
  &.installations {
    padding-bottom: 10rem;
    background-image: url(../assets/backgrounds/DearClimate-tiling_pattern-dither-cloud-white.jpg);
    background-size: 400px;
  }
  .feature-blurb {
    max-width: 600px;
    margin: 2rem auto 2rem auto;
    background-color: white;
    padding: 4rem;
    text-align: left;
    color: black;
    text-transform: uppercase;
    font-family: "Courier New", Courier, monospace;
    & .dark {
      background-color: black;
      color: white;
    }
  }
  .feature-content {
    max-width: 1200px;
    padding: 4rem 0;
    margin: 0 auto;
    &.meditation {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: space-between;
      > div {
        flex-grow: 1;
        margin: 2rem;
      }
    }
  }
  &.letters {
    margin-top: -6rem;
  }
}
/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  .feature-section {
    h1 {
      font-size: 5.5rem;
      margin-bottom: -1rem;
    }
    h2 {
    }
    &.posters {
      padding-bottom: 6rem;
      h1 {
        font-size: 10rem;
        margin-bottom: -2.5rem;
      }
      h2 {
        font-size: 2rem;
      }
    }
    &.meditations {
      // padding-bottom: 30rem;
    }
    &.installations {
    }
    .feature-content {
      padding: 4rem 2rem 2rem 2rem;
    }
    &.letters {
      padding-bottom: 10rem;
    }
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .feature-section {
    padding: 25rem 6rem 25rem 6rem;
    h1 {
      font-size: 10rem;
      margin-bottom: -2rem;
    }
    h2 {
      font-size: 2rem;
    }
    &.posters {
      padding-bottom: 15rem;
      h1 {
        font-size: 20rem;
        // margin-bottom: -4.5rem;
      }
      h2 {
        font-size: 3rem;
      }
    }
    &.meditations {
      padding-bottom: 25rem;
    }
    &.installations {
      padding-bottom: 25rem;
    }
    &.letters {
      padding-bottom: 20rem;
    }
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}

.contact-section {
  max-width: 600px;
  margin: 4rem auto 12rem auto;
  padding: 4rem;
  p {
    font-size: 1.8rem;
  }
}
</style>
