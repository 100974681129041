<template>
  <div id="nav">
    <div class="nav-logo">
      <router-link to="/">DEAR CLIMATE</router-link>
    </div>
    <div class="nav-menu-mob" id="nav-menu-mob">
      <img
        v-if="!mobNavOpen"
        src="../assets/nav-hamburger.png"
        class="nav-menu-ico"
        @mousedown="toggleMobNav()"
      />
      <img
        v-if="mobNavOpen"
        src="../assets/nav-close-icon.png"
        class="nav-menu-ico-close"
        @mousedown="toggleMobNav()"
      />
    </div>
    <div
      v-if="mobNavOpen"
      id="nav-links-mob-backdrop"
      @mousedown="closeMobNav()"
    ></div>
    <div v-if="mobNavOpen" class="nav-links-mob" id="nav-links-mob">
      <div class="documentation-links">
        <ul>
          <li>
            <router-link to="/posters">Posters</router-link>
          </li>
          <li>
            <router-link to="/meditations">Meditations</router-link>
          </li>
          <li>
            <router-link to="/installations">Installations</router-link>
          </li>
          <li>
            <router-link to="/letters">Letters</router-link>
          </li>
          <li>
            <router-link to="/words-and-actions">
              Words &amp; Actions
            </router-link>
          </li>
        </ul>
      </div>
      <div class="about-project-links">
        <ul>
          <li>
            <router-link to="/about">About</router-link>
          </li>
          <li>
            <router-link to="/press">Press Coverage</router-link>
          </li>
          <li>
            <router-link to="/contact">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="nav-links">
      <div class="documentation-links">
        <ul>
          <li><router-link to="/posters">Posters</router-link></li>
          <li><router-link to="/meditations">Meditations</router-link></li>
          <li><router-link to="/installations">Installations</router-link></li>
          <li><router-link to="/letters">Letters</router-link></li>
          <li>
            <router-link to="/words-and-actions">
              Words &amp; Actions
            </router-link>
          </li>
        </ul>
      </div>
      <div class="about-project-links">
        <ul>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/press">Press Coverage</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data: function () {
    return {
      mobNavOpen: false,
    };
  },
  watch: {
    $route() {
      this.mobNavOpen = false;
    },
  },
  methods: {
    toggleMobNav: function () {
      this.mobNavOpen = !this.mobNavOpen;
    },
    closeMobNav: function () {
      this.mobNavOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
#nav {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 8.5rem;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  a,
  a:active,
  a:hover,
  a:focus,
  a:visited {
    color: white;
    text-decoration: none;
    border-bottom: 1px solid transparent;
  }
  a:hover,
  a:focus {
    outline: none;
    border-bottom: 2px solid white;
  }
  .nav-logo {
    z-index: 110;
    color: white;
    font-family: "Torque Inline", "HelveticaNeue", "Helvetica Neue", Helvetica,
      Arial, sans-serif;
    font-size: 2.4rem;
  }
}
#nav-links-mob-backdrop {
  z-index: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.nav-menu-mob {
  z-index: 110;
  .nav-menu-ico {
    height: 2.4rem;
    width: auto;
  }
  .nav-menu-ico-close {
    height: 2.4rem;
    width: auto;
  }
}
.nav-links-mob {
  min-width: 100%;
  position: fixed;
  top: 8.5rem;
  right: 0;
  background-color: black;
  padding: 2rem;
  padding-right: 3rem;
  ul {
    list-style: none;
    text-align: left;
    margin: 0;
    li {
      margin: 0;
      padding: 0.5rem 0;
      a,
      a:active,
      a:hover,
      a:focus,
      a:visited {
        color: white;
        text-decoration: none;
      }
      a:hover {
        border-bottom: 2px solid white;
      }
    }
  }
  .documentation-links {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid white;
  }
  .about-project-links {
    padding-top: 1.5rem;
  }
}
.nav-links {
  display: none;
  ul {
    list-style: none;
    margin: 0;
    li {
      margin: 0;
      display: inline-block;
      padding: 0 1.1rem;
      a,
      a:active,
      a:hover,
      a:focus,
      a:visited {
        color: white;
        text-decoration: none;
      }
      a:hover {
        border-bottom: 2px solid white;
      }
    }
  }
  .documentation-links {
    display: flex;
    flex-direction: row;
    padding-right: 1.5rem;
  }
  .about-project-links {
    border-left: 1px solid white;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: row;
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
}

/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  .nav-menu-mob {
    display: none;
  }
  .nav-links-mob {
    display: none;
  }
  .nav-links {
    display: flex;
  }
}
</style>
