<template>
  <div class="poster-featured-item">
    <img
      :key="id"
      :src="pathLong"
      :alt-text="pathShort"
      class="poster-image"
      :class="isEven ? 'rot-2' : 'rot2'"
    />
  </div>
</template>

<script>
export default {
  name: "PosterFeatureItem",
  props: {
    id: Number,
    pathShort: String,
    pathLong: String,
  },
  components: {},
  computed: {
    isEven() {
      if (this.id % 2 === 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.poster-featured-item {
  display: inline-block;
  .poster-image {
    width: 200px;
    margin: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }
}
</style>
