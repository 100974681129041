<template>
  <div class="link-prompter">
    <h1>More from Dear Climate</h1>
    <!-- For each suggested section, show a link box -->
    <div class="links-row">
      <div v-for="link in links" :key="link" class="link-box">
        <h1>
          <router-link :to="link.target">{{ link.title }}</router-link>
        </h1>
        <p>{{ link.description }}</p>
        <div class="view-more">
          <router-link :to="link.target" class="view-more"
            >VIEW MORE</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkPrompter",
  props: {
    links: Object,
  },
  data() {
    return {
      promptLinks: [
        {
          title: "Shows & Events",
          description: "Lectures, panels, and appearances in group shows.",
          target: "/shows-and-events",
        },
        {
          title: "PublicationS & Interviews",
          description: "Writings and interviews by DEAR CLIMATE.",
          target: "/publications-and-interviews",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.link-prompter {
  display: flex;
  flex-direction: column;
  padding: 6rem 2rem;
  h1 {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    font-weight: 900;
    font-size: 1.4rem;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .links-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 1.5rem;
    .link-box {
      flex-grow: 1;
      flex-basis: 100%;
      background-color: #2d2d2d;
      color: white;
      padding: 6rem 4rem;
      margin: 2rem;
      a,
      h1,
      h2,
      p {
        color: white;
        text-decoration: none;
      }
      h1 {
        font-size: 1.8rem;
      }
      p {
        margin-bottom: 0;
      }
      h1 {
        a,
        a:hover,
        a:focus,
        a:active,
        a:visited {
          text-decoration: none;
          display: inline-block;
          margin-bottom: 15px;
        }
        a:hover,
        a:focus,
        a:active {
          text-shadow: -1px 2px 0 black;
        }
      }
      .view-more {
        margin-top: 2rem;
        a,
        a:hover,
        a:focus,
        a:active,
        a:visited {
          border-bottom: 1px solid white;
          margin-bottom: 1px;
        }
        a:hover,
        a:focus,
        a:active {
          border-bottom: 2px solid white;
          margin-bottom: 0;
        }
      }
    }
  }
}
/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .link-prompter {
    .links-row {
      flex-direction: row;
    }
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}
</style>
